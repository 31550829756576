import React, { useState } from "react";
import { Alert, Button, Flex, Heading, SelectField, Text } from "@aws-amplify/ui-react";
import { useRecoilValue } from "recoil";
import { userIdSelector } from "../recoil/uiState";
import { isProdEnvironment } from "../utils";
import { submitInference } from "../api/inferenceUtils";

interface InferenceSelectFieldProps {
  subjectId: string;
  slideIds: string[];
}

const inferenceTypes = [
  {
    InferenceType: "MIT",
    name: "Mitoses Index",
    info: "Automatic detection of mitoses",
    isRunning: false,
    isDisabled: false // Now being used
  },
  {
    InferenceType: "IMN",
    name: " ImmunoProfile (Lymphocites detection and classification)",
    info: "Automatic detection of lymphocites T/B",
    isRunning: false,
    isDisabled: false
  },
  {
    InferenceType: "TSR",
    name: "Deep Stromal Score (Tumour Stroma Identification)",
    info: "Automatic detection of tissue type, developped initially for colorectal cancer.",
    isRunning: false,
    isDisabled: false
  }
];

export const InferenceSelectField: React.FC<InferenceSelectFieldProps> = ({ subjectId, slideIds }) => {
  const [selectedInferenceId, setSelectedInferenceId] = useState<string>("");
  const [inferences, setInferences] = useState<{
    InferenceType: string;
    name: string;
    info: string;
    isRunning: boolean;
    isDisabled: boolean;
  }[]>(inferenceTypes);
  const userId = useRecoilValue(userIdSelector);
  const [modelVersion, setModelVersion] = useState('stable');
  const [isJobRunning, setIsJobRunning] = useState(false);

  const selectedInference = inferences.find(inference => inference.InferenceType === selectedInferenceId);

  const handleInference = async () => {
    if (selectedInference && !selectedInference.isDisabled) {
      const responses = await submitInference(
        userId,
        subjectId,
        slideIds,
        selectedInference,
        modelVersion
      );
      console.log("Responses:", responses);
    }
  };

  return (
    <div style={{ marginBlockEnd: "2rem" }}>
      <Heading color="#333" textAlign="center">Compute a new Inference</Heading>
      <br />
      <SelectField
        label="AI Inference model available:"
        placeholder="Select an inference type"
        value={selectedInferenceId}
        onChange={(e) => setSelectedInferenceId(e.target.value)}
      >
        {inferences.map(inference => (
          <option
            key={inference.InferenceType}
            value={inference.InferenceType}
            disabled={inference.isDisabled} // Using isDisabled here
          >
            {inference.name} {inference.isDisabled ? "(Coming Soon)" : ""}
          </option>
        ))}
      </SelectField>
      
      {selectedInference && (
        <Alert
          isDismissible={false}
          hasIcon={false}
          heading="Inference Info"
          style={{ marginBlock: "2rem" }}
        >
          <Flex direction="column" gap="small">
            <Text>Inference Name: {selectedInference.name}</Text>
            <Text>{selectedInference.info}</Text>
            <Flex direction="column" justifyContent="flex-start" alignContent="center">
              {!isJobRunning && (
                <Button
                  onClick={handleInference}
                  variation="primary"
                  disabled={isJobRunning || selectedInference.isDisabled} // Added isDisabled check
                >
                  {selectedInference.isDisabled ? "Coming Soon" : "Launch AI Inference"}
                </Button>
              )}
            </Flex>
          </Flex>
        </Alert>
      )}
    </div>
  );
};