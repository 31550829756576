import React, { useEffect, useState } from "react";
import { Badge, Button, Heading, Table, TableBody, TableCell, TableHead, TableRow } from "@aws-amplify/ui-react";
import { API } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { getTopThreeProbablities, stringifyProbabilities, getMitoticIndex } from "../utils";

// Define the structure of each inference object
interface Inference {
    results: object | undefined;
    job_status: string;
    job_progress: string;
    inference_type: string;
    id: string;
}

interface InferencesSummaryTableProps {
    userId: string;
    subjectId: string;
    slideId: string;
    visibleMap: string | null;
    setVisibleMap: React.Dispatch<React.SetStateAction<string | null>>;
}

interface SACResults {
    probabilities: Record<string, number>;
}

interface MITResults {
    MitoticIndex: number;
    overall_density: number;
    // Add any other properties specific to MIT results here
}

export default function InferencesSummaryTable({
    userId,
    subjectId,
    slideId,
    visibleMap,
    setVisibleMap,
}: InferencesSummaryTableProps) {
    let navigate = useNavigate();
    const [inferences, setInferences] = useState<Inference[]>([]);

    useEffect(() => {
        // Define the function to fetch inferences
        const fetchInferences = async () => {
            if (userId && subjectId && slideId) {
                try {
                    const response = await API.get('histopathologyAPI', `/users/${userId}/subjects/${subjectId}/slides/${slideId}/inferences`, {});
                    setInferences(response.data);

                } catch (error) {
                    console.error('Error fetching inferences:', error);
                }
            }
        };

        fetchInferences();
        const intervalId = setInterval(fetchInferences, 5000); // Fetch every 5 seconds

        return () => clearInterval(intervalId);
    }, [userId, subjectId, slideId]);


    const handleRemoveInferenceClicked = async (id: string) => {
        try {
            await API.del('histopathologyAPI', `/users/${userId}/subjects/${subjectId}/slides/${slideId}/inferences/${id}`, {});
            setInferences(inferences.filter(inference => inference.id !== id));
            setVisibleMap(null);
        } catch (error) {
            console.error('Error removing inference:', error);
        }
    }

    const handleDetailsInferenceClicked = (inferenceID: string) => {
        navigate(`/subjects/${subjectId}/slides/${slideId}/inferences/${inferenceID}/view`);
    }

    const handleViewInferenceClicked = (inferenceID: string) => {
        if (inferenceID == "MIT") setVisibleMap(visibleMap !== "mitosesMap" ? "mitosesMap" : null);
        else if (inferenceID == "SAC") setVisibleMap(visibleMap !== "attentionMap" ? "attentionMap" : null);
        else if (inferenceID == "IMN") setVisibleMap(visibleMap !== "imnMap" ? "imnMap" : null);
        else if (inferenceID == "TSR") setVisibleMap(visibleMap !== "tsrMap" ? "tsrMap" : null);

    }

    return (
        <div className="w-full overflow-x-auto">
            <Heading color="#333" textAlign="center" className="mb-6">Completed Inferences</Heading>
            <Table>
                <TableHead className="hidden md:table-header-group">
                    <TableRow>
                        <TableCell>Inference ID</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Info</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {inferences.map((inference, index) => (
                        <TableRow key={inference.inference_type} className="md:table-row flex flex-col border-b md:border-none">
                            <TableCell className="md:table-cell block">
                                <span className="md:hidden font-bold mr-2">Inference ID:</span>
                                {inference.inference_type}
                            </TableCell>
                            <TableCell className="md:table-cell block">
                                <span className="md:hidden font-bold mr-2">Status:</span>
                                <Badge variation={inference.job_status !== "COMPLETED" ? 'info' : 'success'}>
                                    {inference.job_status}
                                </Badge>
                            </TableCell>
                            <TableCell className="md:table-cell block">
                                <span className="md:hidden font-bold mr-2">Info:</span>
                                <div>
                                    {inference.job_status !== "COMPLETED" ? (
                                        `Inference is still in progress: ${Math.round(parseFloat(inference.job_progress))}%`
                                    ) : inference.inference_type === 'SAC' && inference.results ? (
                                        stringifyProbabilities(getTopThreeProbablities((inference.results as SACResults).probabilities))
                                    ) : inference.inference_type === 'MIT' && inference.results ? (
                                        <>
                                            <div>{`Mitotic index: ${(inference.results as MITResults).MitoticIndex}`}</div>
                                            <div>{`Overall Density: ${(inference.results as MITResults)?.overall_density?.toFixed(2)}`}</div>
                                        </>
                                    ) : null}
                                </div>
                                <div>
                                    {inference.job_status == "COMPLETED" && (
                                        <Button variation="link" onClick={() => handleDetailsInferenceClicked(inference.id)}>
                                            ...
                                        </Button>
                                    )}
                                </div>
                            </TableCell>
                            <TableCell className="md:table-cell block">
                                <span className="md:hidden font-bold mr-2">Actions:</span>
                                <div className="flex gap-2">
                                    {inference.job_status == "COMPLETED" &&
                                        <div className="relative group inline-block">
                                            <Button
                                                variation="link"
                                                onClick={() => handleViewInferenceClicked(inference.inference_type)}
                                            >
                                                View
                                            </Button>
                                            <div className="absolute invisible group-hover:visible opacity-0 group-hover:opacity-100 transition bg-gray-800 text-white text-sm rounded py-1 px-2 bottom-full left-1/2 -translate-x-1/2 mb-2 whitespace-nowrap">
                                                Press Ctrl+Q to toggle the view
                                                {/* Arrow */}
                                                <div className="absolute top-full left-1/2 -translate-x-1/2 border-4 border-transparent border-t-gray-800"></div>
                                            </div>
                                        </div>
                                    }
                                    <Button variation="link"
                                        onClick={() => handleRemoveInferenceClicked(inference.id)}>
                                        Delete
                                    </Button>
                                </div>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
}

