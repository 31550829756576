import { useEffect, useState } from "react";
import { Breadcrumbs, Flex, Button, Heading } from "@aws-amplify/ui-react";
import { useRecoilValue } from "recoil";
import { userIdSelector } from "../../recoil/uiState";
import { useParams, useNavigate } from "react-router-dom";
import { API } from "aws-amplify";
import { InferenceSelectField } from "../../components/InferenceSelectField";
import ViewerComp from "../../feature/ViewerComp";
import WhiteCard from "../../components/WhiteCard";
import InferencesSummaryTable from "../../feature/InferencesSummaryTable";
import DeletePopup from '../../components/DeletePopup';
export default function SlideDetail() {
    const userId = useRecoilValue(userIdSelector);
    const { slideId, subjectId } = useParams();
    const [slideURL, setSlideURL] = useState<string>("");
    const [visibleMap, setVisibleMap] = useState<string | null>(null);
    const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
    const navigate = useNavigate();
    // If user, slide or subject Ids are undefined then immediately redirect to the home page
    const allParamsValid = slideId !== undefined && subjectId !== undefined;

    if (!allParamsValid) {
        window.location.href = "/";
    }

    useEffect(() => {
        const fetchSlideURL = async () => {
            if (userId && subjectId && slideId) {
                try {
                    const presignedUrlResponse = await API.get('histopathologyAPI', '/presignedUrl_get', {
                        queryStringParameters: {
                            bucket: 'svs-to-compute',
                            key: `subjects/${subjectId}/slides/${slideId}/slide.tif`
                        }
                    });
                    setSlideURL(presignedUrlResponse.url);
                } catch (error) {
                    console.error("Error fetching slide:", error);
                }
            }
        };
        fetchSlideURL();
        let interval: number = window.setInterval(() => {
            fetchSlideURL();
        }, 900000); // 900000 milliseconds = 15 minutes

        return () => clearInterval(interval);

    }, [userId, subjectId, slideId]);

    return (
        <>
            <Breadcrumbs
                items={[
                    {
                        href: '/slides',
                        label: 'Slide Table',
                    },
                    {
                        label: 'Slide ' + slideId,
                    }
                ]}
            />

            <Heading level={4}><strong>Slide {slideId} Details</strong></Heading>

            <WhiteCard>
                <Flex direction="column" gap="2rem">
                    {slideURL && <ViewerComp
                        url={slideURL}
                        visibleMap={visibleMap}
                        slideParameters={{
                            userId: userId,
                            subjectId: subjectId,
                            slideId: slideId,
                            level: 'protected',
                            slidePath: `subjects/${subjectId}/slides/${slideId}`,
                        }}
                    />}
                    {allParamsValid && <>
                        <InferencesSummaryTable
                            userId={userId}
                            subjectId={subjectId}
                            slideId={slideId}
                            visibleMap={visibleMap}
                            setVisibleMap={setVisibleMap}
                        />
                    </>}
                    {subjectId && slideId && (
                        <InferenceSelectField
                            subjectId={subjectId}
                            slideIds={[slideId]}
                        />)}
                </Flex>
                <Button variation="primary" colorTheme="error" onClick={() => setIsDeleteConfirmationOpen(true)}>Delete Slide</Button>
                {isDeleteConfirmationOpen && (
                    <DeletePopup
                        selectedRows={[slideId!]}
                        subjectId={subjectId}
                        onClose={() => {
                            setIsDeleteConfirmationOpen(false);
                            if (subjectId) {
                                navigate(`/subjects/${subjectId}/slides`);
                            }

                            // Perform any additional actions after deleting the slide
                        }} />
                )}
            </WhiteCard>

        </>

    );
}
