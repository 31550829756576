import { MutableRefObject } from "react";
import { API } from "aws-amplify";
import { Viewer } from "openseadragon";
import Papa from "papaparse";

interface AttentionDataCSVItem {
    attention_KDE_mag40: number;
    attention_KDE_mag20: number;
    attention_KDE_mag10: number;
    attention_mag40: number;
    attention_mag20: number;
    attention_mag10: number;
    coords_x: number;
    coords_y: number;
}

interface TSRDataCSVItem {
    coords_x: number;
    coords_y: number;
    class: string;
    confidence: number;
    tissue_type: string;
}


interface ObjectCSVItem {
    coords_x: number;
    coords_y: number;
    conf: number;
    class: string;
}

interface SlideParameters {
    userId: string;
    subjectId: string;
    slideId: string;
    level: 'public' | 'protected' | 'private';
    slidePath: string;
}

const OBJECT_CLASS_COLORS = {
    '0': '#8dd3c7',
    '1': '#ffffb3',
    '2': '#bebada'
};

const PATCH_CLASS_COLORS = {
    '0': '#a6cee3',
    '1': '#1f78b4',
    '2': '#b2df8a',
    '3': '#33a02c',
    '4': '#fb9a99',
    '5': '#e31a1c',
    '6': '#fdbf6f',
    '7': '#ff7f00',
    '8': '#cab2d6'
};


export const paintObject = async function(
    viewer: Viewer,
    heatmapRef: MutableRefObject<HeatmapOverlay | null>,
    filteredObjectData: ObjectCSVItem[]
) {
    if (!heatmapRef.current) {
        heatmapRef.current = new HeatmapOverlay(viewer, {
            typeofHeatmap: 'points'
        });
    }
    else {
        heatmapRef.current.cfg.typeofHeatmap = 'points';  
    }
    const points = filteredObjectData.map((item: ObjectCSVItem) => ({
        x: Number(item.coords_x),
        y: Number(item.coords_y),
        color: OBJECT_CLASS_COLORS[item.class as keyof typeof OBJECT_CLASS_COLORS] || '#000000',
        value: 1.0,
        radius: 1
    }));

    const data = {
        min: 0,
        max: 1,
        data: points
    };

    if (heatmapRef.current) {
        heatmapRef.current.setData(data);
    }
};

export const paintBbox = function(
    heatmapRef: MutableRefObject<HeatmapOverlay | null>,
    bbox: number[] | null
) {
    if (heatmapRef.current && bbox) {
        heatmapRef.current.setBbox(bbox);
    }
};



export const paintTSR = async function(
    viewer: Viewer,
    heatmapRef: MutableRefObject<HeatmapOverlay | null>,
    filteredObjectData: TSRDataCSVItem[]
) {

    console.log('TSR Data:', filteredObjectData); // Add this debug line
    if (!heatmapRef.current) {
        heatmapRef.current = new HeatmapOverlay(viewer, {
            typeofHeatmap: 'boxes'
        });
    }
    else {
        heatmapRef.current.cfg.typeofHeatmap = 'boxes';  // Fixed syntax error here
    }

    // Convert TSR boxes to points with fixed size 
    const points = filteredObjectData.map((item: TSRDataCSVItem) => ({
        x: Number(item.coords_x),
        y: Number(item.coords_y),
        value: Number(item.confidence),
        radius: 128, 
        color: PATCH_CLASS_COLORS[item.class as keyof typeof PATCH_CLASS_COLORS] || '#000000',
    }));

    console.log('Processed TSR points:', points);
    const data = {
        min: 0,
        max: 1,
        data: points
    };

    if (heatmapRef.current) {
        heatmapRef.current.setData(data);
    }
};